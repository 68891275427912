<template>
	<div class="animated fadeIn">
		<b-card>
			<b-card-title><i class="icon-location-pin"></i> Storage Location</b-card-title>
			<b-card-sub-title>Manages the storage location registration</b-card-sub-title>
			<b-container fluid class="mt-4">
				<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

				<!-- Filter  -->
				<b-row class="mt-2">
					<b-col sm="12" md="3" lg="3">
						<b-button v-b-popover.hover.right="'Toggle to show/hide filter options'" v-b-toggle.collapse-1
							class="filter">
							FILTER OPTIONS
						</b-button>
					</b-col>
					<b-col sm="12">
						<!-- Collapsible Filter Options -->
						<b-collapse id="collapse-1" class="mt-2">
							<b-card>
								<b-row no-gutters>
									<b-col lg="4" md="6" sm="12" class="mr-2">
										<b-form-group label="Company">
											<v-select class="style-chooser" label="text" :options="companyOptions"
												:reduce="(company) => company.value" v-model="filterBy.company">
												<template v-slot:no-options="{ search, searching }">
													<template v-if="searching">
														No results found for
														<em>
															<strong>{{ search }}</strong>
														</em>
													</template>
													<em :style="{ opacity: 0.5 }" v-else>
														Start typing to search for a company
													</em>
												</template>
											</v-select>
										</b-form-group>
									</b-col>
									<b-col lg="4" md="6" sm="12" class="mr-2">
										<b-form-group label="Status">
											<v-select class="style-chooser" label="text" :options="statusOptions"
												:reduce="(isActive) => isActive.value" v-model="filterBy.isActive">
												<template v-slot:no-options="{ search, searching }">
													<template v-if="searching">
														No results found for
														<em>
															<strong>{{ search }}</strong>
														</em>
													</template>
													<em :style="{ opacity: 0.5 }" v-else>
														Start typing to search for status
													</em>
												</template>
											</v-select>
										</b-form-group>
									</b-col>
								</b-row>
								<b-row no-gutters>
									<b-col lg="4" md="6" sm="12" class="mr-2">
										<b-form-group label="Storage Location">
											<v-select class="style-chooser" label="text" :options="storageLocationOptions"
												:reduce="(storageLoc) => storageLoc.value"
												v-model="filterBy.storageLocation">
												<template v-slot:no-options="{ search, searching }">
													<template v-if="searching">
														No results found for
														<em>
															<strong>{{ search }}</strong>
														</em>
													</template>
													<em :style="{ opacity: 0.5 }" v-else>
														Start typing to search for a company
													</em>
												</template>
											</v-select>
										</b-form-group>
									</b-col>
								</b-row>
								<b-row no-gutters>
									<b-col sm="12">
										<b-button class="mr-1" variant="success" @click="onFilterRequest">
											Search
										</b-button>

										<b-button class="mr-1" variant="primary" @click="resetFilters">
											Reset
										</b-button>
									</b-col>
								</b-row>
							</b-card>
						</b-collapse>
					</b-col>
				</b-row>

				<!-- Select Actions and Items Per Page Options -->
				<b-row>
					<b-col sm="6" md="3" class="mt-4 mb-2">
						<b-dropdown text=" Select Actions " variant="dark" slot="append">
							<b-dropdown-item v-b-modal.add-storage-location v-show="!isViewer">
								Add Storage Location
							</b-dropdown-item>
							<b-dropdown-item>
								<json-excel :data="exportData" :fields="exportFields" type="xls" :name="fileName + '.xls'">
									Export Storage Locations in Excel
								</json-excel>
							</b-dropdown-item>
							<b-dropdown-item>
								<json-excel :data="exportData" :fields="exportFields" type="csv" :name="fileName + '.csv'">
									Export Storage Locations to CSV
								</json-excel>
							</b-dropdown-item>
						</b-dropdown>
					</b-col>
					<b-col sm="6" md="4" offset-md="5" class="mt-4 mb-2 text-md-right">
						<b-input-group prepend="Show" append="/ Page">
							<b-form-select :options="pageOptions" v-model="perPage" />
						</b-input-group>
					</b-col>
				</b-row>

				<b-table show-empty striped hover :items="items" :fields="fields" :current-page="currentPage"
					:per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
					:sort-direction="sortDirection" :responsive="true">
					<template v-slot:cell(description)="row">
						{{
							row.item.description && row.item.description.length > 0
							? row.item.description
							: '-'
						}}
					</template>
					<template v-slot:cell(actions)="row">
						<span class="text-nowrap">
							<b-button size="sm" v-b-tooltip.hover.top="'Show/Hide Other Details'" variant="success"
								@click.stop="row.toggleDetails" class="mr-1">
								<i class="fa fa-eye-slash" v-if="row.detailsShowing"></i>
								<i class="fa fa-eye" v-else></i>
							</b-button>

							<b-button size="sm" v-b-modal.edit-storage-location v-b-tooltip.hover.top="'Edit Details'"
								variant="dark" @click.stop="updateSelStorageLocation(row.item)" class="mr-1"
								v-show="!isViewer">
								<i class="fa fa-pencil"></i>
							</b-button>

							<b-button size="sm" v-b-modal.print-storage-location v-b-tooltip.hover.top="'Print QR Code'"
								variant="warning" @click.stop="printQrCode(row.item)" class="mr-1" v-show="!isViewer">
								<i class="fa fa-print"></i>
							</b-button>
						</span>
					</template>

					<template v-slot:cell(status)="row">
						<a @click="updateSelStorageLocation(row.item)" v-if="!isViewer">
							<b-form-checkbox name="status" v-b-modal.activate-storage-location v-model="row.item.isActive"
								switch size="sm">
								{{ row.item.isActive === 'true' ? 'Active' : 'Inactive' }}
							</b-form-checkbox>
						</a>
						<span v-else>{{
							row.item.isActive === 'true' ? 'Active' : 'Inactive'
						}}</span>
					</template>

					<template slot="row-details" slot-scope="row">
						<StorageLocationDetailsView :row="row" />
					</template>
				</b-table>

				<b-row>
					<b-col md="8" sm="12" class="my-1">
						<span class="totalDisplay">Total: {{ totalRows }}</span>
					</b-col>
					<b-col md="4" sm="12" class="my-1">
						<b-pagination align="right" :total-rows="totalRows" :per-page="perPage" v-model="currentPage"
							class="my-0" />
					</b-col>
				</b-row>
			</b-container>
		</b-card>

		<!-- Modals here -->
		<AddStorageLocation :companyFilter="filterBy.company" :companyOptions="companyOptions"
			:allCompaniesObj="allCompaniesObj" :allStorageLocationsObj="allStorageLocationsObj" />
		<EditStorageLocation :companyOptions="companyOptions" :allCompaniesObj="allCompaniesObj"
			:allStorageLocationsObj="allStorageLocationsObj" />
		<ActivateStorageLocation :allCompaniesObj="allCompaniesObj" :allStorageLocationsObj="allStorageLocationsObj" />
		<PrintStorageLocation />

	</div>
</template>

<script>
// Components
import AddStorageLocation from '@/views/setup/storageLocation/AddStorageLocation';
import EditStorageLocation from '@/views/setup/storageLocation/EditStorageLocation';
import ActivateStorageLocation from '@/views/setup/storageLocation/ActivateStorageLocation';
import StorageLocationDetailsView from '@/views/setup/storageLocation/StorageLocationDetailsView';
import PrintStorageLocation from './storageLocation/PrintStorageLocation.vue';

// Util
import { DateUtil } from '@/utils/dateutil';
import { DropDownItemsUtil } from '@/utils/dropDownItemsUtil';

// API
import storageLocationApi from '@/api/storageLocationApi';

// Others
import config from '@/config/env-constants';
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import JsonExcel from 'vue-json-excel';
import _ from 'lodash';

export default {
	name: 'storage-location',
	components: {
		AddStorageLocation,
		EditStorageLocation,
		ActivateStorageLocation,
		StorageLocationDetailsView,
		PrintStorageLocation,
		Loading,
		JsonExcel,
	},
	data() {
		return {
			items: [],
			fields: [
				{
					key: 'name',
					sortable: true,
				},
				{
					key: 'description',
					sortable: true,
				},
				{
					key: 'company',
					sortable: true,
				},
				{
					key: 'address',
					sortable: false,
				},
				'actions',
				'status',
			],
			currentPage: 1,
			perPage: 10,
			totalRows: 0,
			pageOptions: [5, 10, 15, 25, 50, 100],
			sortBy: null,
			sortDesc: false,
			sortDirection: 'asc',
			filter: null,

			companyOptions: [],
			storageLocationOptions: [],
			statusOptions: config.statusOptionsWithDefault,

			selStorageLocation: {},

			allCompaniesObj: {},
			allStorageLocationsObj: {},

			defaultFilterBy: {
				company: Object.assign({}, config.companyDefaultValue),
				storageLocation: Object.assign({}, config.storageLocationDefaultValue),
				isActive: null,
			},
			filterBy: {
				company: Object.assign({}, config.companyDefaultValue),
				storageLocation: Object.assign({}, config.storageLocationDefaultValue),
				isActive: null,
			},

			prevFilter: {},

			isSuperAdmin: this.$store.getters.isSuperAdmin,
			isViewer: this.$store.getters.isViewer,
			loggedUser: this.$store.getters.loggedUser,
			loggedUserCompany: this.$store.getters.loggedUserCompany,
			// Check for loader
			isLoading: false,
		};
	},
	watch: {
		'filterBy.company': function () {
			if (this.filterBy.company.id) {
				this.storageLocationOptions =
					DropDownItemsUtil.retrieveStorageLocationOfCurrCompany(
						this.filterBy.company.id,
						this.allStorageLocationsObj
					);
				this.filterBy.storageLocation = Object.assign({}, config.storageLocationDefaultValue);
			} else {
				this.storageLocationOptions =
					DropDownItemsUtil.retrieveStorageLocationItems(
						this.allStorageLocationsObj
					);
			}
		},
	},
	computed: {
		/**
		 * Returns the set of data to be included in the export. For now this just
		 * returns the data as is.
		 *
		 * @returns {Array} the set of data to be included in the export.
		 */
		exportData() {
			return this.items;
		},

		/**
		 * Derives the field information based from the data table configuration.
		 *
		 * @returns {object} the fields to be included in the export.
		 */
		exportFields() {
			return {
				Name: 'name',
				Description: 'description',
				Company: 'company',
				Address: 'address',
			};
		},

		fileName() {
			let currTimeStamp = DateUtil.getCurrentTimestamp();
			return (
				'StorageLocation-' + DateUtil.getDateInDDMMYYYYHHSSFormat(currTimeStamp)
			);
		},
	},
	mounted() {
		setTimeout(() => {
			try {
				// Don't initiate data retrieval when the account is not authenticated
				if (!this.$store.getters.isAuthenticated) {
					return;
				}

				// Show loader
				this.isLoading = true;

				this.allCompaniesObj = this.$store.getters.companies;
				this.companyOptions = DropDownItemsUtil.retrieveCompanyItems(
					this.allCompaniesObj, this.loggedUserCompany
				);

				if (!this.isSuperAdmin) {
					this.filterBy.company = DropDownItemsUtil.getCompanyItem(
						this.loggedUserCompany
					);
					this.defaultFilterBy.company = DropDownItemsUtil.getCompanyItem(
						this.loggedUserCompany
					);
				}

				this.allStorageLocationsObj = this.$store.getters.storageLocations;
				this.storageLocationOptions =
					DropDownItemsUtil.retrieveStorageLocationItems(
						this.allStorageLocationsObj
					);

				this.filterTableContent();
			} catch (error) {
				this.$toaster.error(
					'Error loading data. Please reload the page again.'
				);
			}

			// hide loading indicator
			this.isLoading = false;
		}, config.timeout);

		// Event Listeners
		EventBus.$on('onCloseAddStorageLocation', (storageLocationObj) => {
			this.updateTable(storageLocationObj);
		});
		EventBus.$on('onCloseEditStorageLocation', (storageLocationObj) => {
			this.updateTable(storageLocationObj);
		});
	},
	methods: {
		updateTable(locObj) {
			if (!_.isEmpty(locObj)) {
				this.allStorageLocationsObj[locObj.id] = locObj;
				this.$store.dispatch(
					'updateAllStorageLocations',
					this.allStorageLocationsObj
				);

				this.filterTableContent();
			}
		},
		filterTableContent() {
			let filteredObj = Object.assign({}, this.allStorageLocationsObj);

			_.forEach(this.allStorageLocationsObj, (loc, locId) => {
				if (
					this.filterBy.company.id !== null &&
					this.filterBy.company.id !== loc.companyId
				) {
					delete filteredObj[locId];
				}

				if (
					this.filterBy.storageLocation.id !== null &&
					this.filterBy.storageLocation.id !== loc.id
				) {
					delete filteredObj[locId];
				}

				if (this.filterBy.isActive !== null &&
					this.filterBy.isActive !== loc.isActive) {
					delete filteredObj[locId];
				}
			});

			this.items = Object.values(filteredObj);
			this.items = _.sortBy(this.items, ['name']);
			this.totalRows = this.items.length;
		},
		onFilterRequest() {
			if (!_.isEqual(this.filterBy, this.prevFilter)) {
				this.retrieveData();
				this.prevFilter = Object.assign({}, this.filterBy);
			}
		},
		resetFilters() {
			if (!_.isEqual(this.filterBy, this.defaultFilterBy)) {
				// reset to default
				this.filterBy = Object.assign({}, this.defaultFilterBy);
				this.retrieveData();
				this.prevFilter = Object.assign({}, this.filterBy);
			}
		},
		async retrieveData() {
			try {
				// Show loader
				this.isLoading = true;

				let filter = Object.assign({}, this.filterBy);

				if (this.isSuperAdmin) {
					filter.companyId = this.loggedUserCompany.id;
					const { data } = await storageLocationApi.getStorageLocations(
						filter,
						config.view.ADMIN,
						this.loggedUser.id
					);
					this.allStorageLocationsObj = _.assign(
						this.allStorageLocationsObj,
						data.storageLocations
					);
				} else {
					filter.companyId = this.loggedUserCompany.id;
					const { data } = await storageLocationApi.getStorageLocations(
						filter,
						config.view.COMPANY,
						this.loggedUser.id
					);
					this.allStorageLocationsObj = _.assign(
						this.allStorageLocationsObj,
						data.storageLocations
					);
				}

				this.filterTableContent();
			} catch (error) {
				this.$toaster.error(
					'Error loading data. Please reload the page again.'
				);
			}

			// hide loading indicator
			this.isLoading = false;
		},
		updateSelStorageLocation(item) {
			this.selStorageLocation = item;
			this.$store.commit('SET_CURR_STORAGE_LOCATION', item);
			EventBus.$emit('onUpdateSelStorageLocation', item);
		},

		printQrCode(item) {
			this.selStorageLocation = item;
			this.$store.commit('SET_CURR_STORAGE_LOCATION', item);
			EventBus.$emit('onPrintStorageLocation', item);
		},
	},
	beforeDestroy() {
		EventBus.$off('onCloseAddStorageLocation');
		EventBus.$off('onCloseEditStorageLocation');
	},
};
</script>

<style scoped>
.fa-print {
	color: #fff
}
</style>
