<template>
	<b-modal id="edit-storage-location" title="Edit Storage Location" ref="modal" ok-title="Update" @ok="handleOk"
		@show="onReset" :cancel-disabled="disableConfirmButtons" :ok-disabled="disableConfirmButtons"
		:no-close-on-backdrop="true">
		<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

		<b-form @submit.stop.prevent="handleSubmit" novalidate>
			<b-container fluid>
				<b-form-group label="Company:">
					<b-form-input id="name" name="Name" type="text" v-model="form.company" v-validate="'required'"
						placeholder readonly />
				</b-form-group>

				<b-form-group label="Name:" label-for="name" description>
					<b-form-input id="name" name="Name" type="text" v-model="form.name" v-validate="{
						required: true,
						regex: /^[ÑA-Za-z0-9][ñA-Za-z0-9\s&\'\-,.\/]{1,100}$/,
					}" placeholder="Name" readonly />
					<span v-show="errors.has('Name')" class="help-block">{{
						errors.first('Name')
					}}</span>
				</b-form-group>

				<b-form-group label="Description" label-for="description" description="">
					<b-form-input id="description" name="Description" type="text" v-model="form.description" v-validate="{
						required: false,
						regex: /^[ÑA-Za-z0-9][ñA-Za-z0-9\s&\'\-,.\/]{1,200}$/,
					}" placeholder="Description" />
					<span v-show="errors.has('Description')" class="help-block">
						{{ errors.first('Description') }}
					</span>
				</b-form-group>

				<b-form-group label="Address:" label-for="address"
					description="Please enter the complete address of the storage location">
					<b-form-input id="address" name="Address" type="text" v-model="form.address" v-validate="{
						required: true,
						regex: /^(?=.*[A-Za-z])[A-Za-z\d\s(),.]+$/,
					}" required placeholder="Address" />
					<span v-show="errors.has('Address')" class="help-block">{{
						errors.first('Address')
					}}</span>
				</b-form-group>

				<b-form-group label="Geographical Location:" label-for="geoaddress"
					description="This is the exact coordinates that will be used to locate in the map">
					<b-row class="my-2">
						<b-col lg="6" md="6" sm="12">
							<b-form-group description="Latitude">
								<b-form-input id="latitude" name="Latitude" type="number" v-model="form.geoaddress.latitude"
									v-validate="'required|min_value:-90|max_value:90'" min="-90" max="90" step="0.01"
									placeholder="Latitude" />
							</b-form-group>
							<span v-show="errors.has('Latitude')" class="help-block">{{
								errors.first('Latitude')
							}}</span>
						</b-col>
						<b-col lg="6" md="6" sm="12">
							<b-form-group description="Longitude">
								<b-form-input id="longitude" name="Longitude" type="number"
									v-model="form.geoaddress.longitude" v-validate="'required|min_value:-180|max_value:180'"
									min="-180" max="180" step="0.01" placeholder="Longitude" />
							</b-form-group>
							<span v-show="errors.has('Longitude')" class="help-block">{{
								errors.first('Longitude')
							}}</span>
						</b-col>
						<b-col lg="6" md="6" sm="12">
							<b-link href="https://www.latlong.net/" target="_blank">
								<i class="icon-info"></i>&nbsp;Generate coordinates here
							</b-link>
						</b-col>
					</b-row>
				</b-form-group>

				<b-form-group label="Area Radius (m):" label-for="areaRadius"
					description="Please enter the allowed area's radius relative to the locations coordinates.">

					<b-form-input id="areaRadius" name="Area Radius" type="number" v-model="form.areaRadius"
						v-validate="'required|min_value:10|max_value:100'" min="10" max="100" step="1" onwheel="this.blur()"
						placeholder="Area Radius" />

					<span v-show="errors.has('Area Radius')" class="help-block">{{
						errors.first('Area Radius')
					}}</span>
				</b-form-group>

			</b-container>
		</b-form>
	</b-modal>
</template>

<script>
// Util
import { StorageLocationUtil } from '@/utils/storageLocationUtil';
import { ValidationUtil } from '@/utils/validationUtil';
import { LocationUtil } from '@/utils/locationUtil';

// Others
import EventBus from '@/shared/event-bus';
import config from '@/config/env-constants';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import _ from 'lodash';

export default {
	name: 'edit-storage-location',
	components: {
		Loading,
	},
	props: {
		companyOptions: {
			type: Array,
			required: true,
		},
		allCompaniesObj: {
			type: Object,
			required: true,
		},
		allStorageLocationsObj: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			form: {
				id: '',
				name: '',
				description: '',
				company: null,
				companyId: '',
				address: '',
				geoaddress: {
					latitude: null,
					longitude: null,
				},
				areaRadius: null,
				isActive: '',
				dateCreated: '',
				createdBy: '',
				dateUpdated: '',
				updatedBy: '',
			},
			selCompany: config.companyDefaultValue,

			// Check for loader
			isLoading: false,
		};
	},
	watch: {
		selCompany: function () {
			this.updateCompanySelection();
		},
	},
	computed: {
		disableConfirmButtons() {
			return this.isLoading;
		},
		name() {
			return this.form.name;
		}
	},
	methods: {
		async handleOk(evt) {
			// Prevent modal from closing
			evt.preventDefault();

			// show loading indicator
			this.isLoading = true;

			let isValid = await this.$validator.validateAll();
			if (!isValid) {
				this.$toaster.warning('Please address the field/s with invalid input.');
				// hide loading indicator
				this.isLoading = false;
				return;
			}

			if (!this.selCompany.id) {
				this.$toaster.warning(
					'Please select the company of this storage location.'
				);
				// hide loading indicator
				this.isLoading = false;
				return;
			}

			if (
				StorageLocationUtil.exceedMaximum(
					this.allCompaniesObj[this.selCompany.id],
					this.allStorageLocationsObj,
					this.form
				)
			) {
				this.$toaster.warning(
					'You have exceeded the allowed no of active storage location for this company.'
				);
				// hide loading indicator
				this.isLoading = false;
				return;
			}

			if (
				ValidationUtil.objectHasField('name', this.allStorageLocationsObj) &&
				this.form.name != this.$store.getters.currStorageLocation.name
			) {
				this.$toaster.warning('Storage location name already exists.');
				// hide loading indicator
				this.isLoading = false;
				return;
			}

			// Removes excess whitespace
			this.form.address = ValidationUtil.removeExcessWhiteSpace(
				this.form.address
			);
			this.handleSubmit();
		},

		async handleSubmit() {
			// show loading indicator
			this.isLoading = true;

			try {
				const result = await this.$store.dispatch(
					'updateStorageLocation',
					this.form
				);

				// hide loading indicator
				this.isLoading = false;

				if (result.isSuccess) {
					this.$toaster.success(
						`Storage Location "${this.name}" was updated successfully.`
					);
					EventBus.$emit('onCloseEditStorageLocation', result.data);
					this.$refs.modal.hide();
				} else {
					this.$toaster.error(
						`Error updating storage location "${this.name}". Please try again.`
					);
				}
			} catch (error) {
				// hide loading indicator
				this.isLoading = false;

				this.$toaster.error(
					`Error updating storage location "${this.name}". Please try again.`
				);
			}
		},
		updateCompanySelection() {
			if (!_.isEmpty(this.selCompany)) {
				this.form.company = this.selCompany.name;
				this.form.companyId = this.selCompany.id;
			}
		},
		onReset() {
			// init form
			let storageLocation = this.$store.getters.currStorageLocation;
			let storageLocationJSON = JSON.stringify(storageLocation);
			this.form = JSON.parse(storageLocationJSON);

			// pre-process geopoint
			storageLocation.geoaddress = LocationUtil.getGeoaddress(
				storageLocation.geoaddress
			);
			this.form.geoaddress.latitude = storageLocation.geoaddress.latitude;
			this.form.geoaddress.longitude = storageLocation.geoaddress.longitude;

			// init company
			this.selCompany = {
				id: storageLocation.companyId,
				name: storageLocation.company,
			};

			// init area radius
			this.form.areaRadius = storageLocation.areaRadius;

			// reset validation
			this.isLoading = false;
			this.$validator.reset();
			this.errors.clear();
		},
	},
};
</script>

<style></style>

