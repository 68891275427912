import firebase from 'firebase/app';
import _ from 'lodash';


export const StorageLocationUtil = {

	exceedMaximum(company, storageLocations, currLocation) {
		let maxNo = company.noOfStorageLocations ? company.noOfStorageLocations : 0;
		if (!_.isEmpty(storageLocations)) {
			let activeLocations = _.filter(storageLocations, o => {
				return o.isActive === 'true' && company.id === o.companyId;
			});
			let activeLocationsIds = _.map(activeLocations, 'id');
			if (currLocation && activeLocationsIds.includes(currLocation.id)) {
				// skip if the currLocation is already part of current active locations
				return false;
			} else {
				return _.size(activeLocations) >= maxNo;
			}
		}
		return false;
	},

	getGeoaddress(geoaddress) {
		let latitude = '';
		let longitude = '';

		if (geoaddress.latitude) {
			latitude = parseFloat(geoaddress.latitude);
		}

		if (geoaddress.longitude) {
			longitude = parseFloat(geoaddress.longitude);
		}

		if (geoaddress._latitude) {
			latitude = parseFloat(geoaddress._latitude);
		}

		if (geoaddress._longitude) {
			longitude = parseFloat(geoaddress._longitude);
		}

		if (geoaddress._lat) {
			latitude = parseFloat(geoaddress._lat);
		}

		if (geoaddress._long) {
			longitude = parseFloat(geoaddress._long);
		}

		if (_.isEmpty(geoaddress)) {
			latitude = parseFloat(0);
			longitude = parseFloat(0);
		}

		return new firebase.firestore.GeoPoint(latitude, longitude);
	},

	addLatitudeAndLongitudeFields(sourceOrDestination) {
		let latitude = sourceOrDestination.geoaddress._latitude;
		let longitude = sourceOrDestination.geoaddress._longitude;

		if (!latitude) {
			latitude = sourceOrDestination.geoaddress.latitude;
		}
		if (!longitude) {
			longitude = sourceOrDestination.geoaddress.longitude;
		}

		sourceOrDestination.latitude = latitude;
		sourceOrDestination.longitude = longitude;

		return sourceOrDestination;
	}
}
