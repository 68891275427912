<template>
	<div>
		<b-card no-body>
			<b-tabs card>
				<b-tab title="Primary Information" active>
					<b-card-text>
						<b-form-group
							label="Name:"
							label-cols-sm="3"
							label-class="font-weight-bold pt-0"
							label-align-sm="right"
						>
							{{ row.item.name }}
						</b-form-group>

						<b-form-group
							label="Description:"
							label-cols-sm="3"
							label-class="font-weight-bold pt-0"
							label-align-sm="right"
						>
							{{ row.item.description ? row.item.description : '-' }}
						</b-form-group>

						<b-form-group
							label="Address:"
							label-cols-sm="3"
							label-class="font-weight-bold pt-0"
							label-align-sm="right"
						>
							{{ row.item.address }}
						</b-form-group>

						<b-form-group
							label="Geoaddress:"
							label-cols-sm="3"
							label-class="font-weight-bold pt-0"
							label-align-sm="right"
						>
							<b-button
								size="sm"
								v-b-tooltip.hover.top="'Show/Hide Map Location'"
								@click.stop="showStorageLocation(row.item)"
								variant="success"
								class="mr-1"
							>
								<i class="fa fa-map-marker fa-lg"></i>
							</b-button>
							{{ getCoordinates(row.item.geoaddress) }}
						</b-form-group>

						<b-form-group
							label="Date Created:"
							label-cols-sm="3"
							label-class="font-weight-bold pt-0"
							label-align-sm="right"
						>
							{{ getFormattedDateWithTime(row.item.dateCreated) }}
						</b-form-group>

						<b-form-group
							label="Date Updated:"
							label-cols-sm="3"
							label-class="font-weight-bold pt-0"
							label-align-sm="right"
						>
							{{ getFormattedDateWithTime(row.item.dateUpdated) }}
						</b-form-group>

						<b-form-group
							label="Status:"
							label-cols-sm="3"
							label-class="font-weight-bold pt-0"
							label-align-sm="right"
						>
							<span v-if="row.item.isActive === 'true'">
								<b-badge variant="success">Active</b-badge>
							</span>
							<span v-else>
								<b-badge variant="secondary">Inactive</b-badge>
							</span>
						</b-form-group>
					</b-card-text>
				</b-tab>
			</b-tabs>
		</b-card>
		<StorageLocationView :storageLocation="row.item" />
	</div>
</template>

<script>
// Component
import StorageLocationView from '@/views/setup/storageLocation/StorageLocationView';

// Util
import { LocationUtil } from '@/utils/locationUtil';
import { DateUtil } from '@/utils/dateutil';

export default {
	name: 'storage-location-details-view',
	components: {
		StorageLocationView,
	},
	props: {
		row: {
			type: Object,
			required: true,
		},
	},
	methods: {
		getFormattedDateWithTime(date) {
			return DateUtil.getFormattedDateWithTime(date);
		},
		getCoordinates(geoaddress) {
			geoaddress = LocationUtil.getGeoaddress(geoaddress);

			return (
				'(' +
				geoaddress.latitude.toFixed(6) +
				', ' +
				geoaddress.longitude.toFixed(6) +
				')'
			);
		},
		showStorageLocation(item) {
			this.$bvModal.show('storage-location-view-' + item.id);
		},
	},
};
</script>

<style scoped>
</style>